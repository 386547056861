<template>
  <div>
    <MLMenu/>
    <div class="ml-content">
      <nuxt/>
    </div>
    <MLFooter/>
    <div class="bg-light sticky-notification" v-if="deferredPrompt">
      <div class="row">
        <div class="col-12">
          <h6>Bạn có muốn cài đặt ứng dụng không!</h6>
        </div>
      </div>
      <div class="row">
        <div class="col-6 text-right">
          <button class="btn btn-danger" style="border-radius: 16px;" @click="handleInstallBtnNo">Không</button>
        </div>
        <div class="col-6 ">
          <button class="btn btn-success" style="border-radius: 16px;" @click="handleInstallBtnYes">Cài Đặt</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Cookies from 'js-cookie'
import { mapState } from 'vuex'
import MLMenu from '~/components/mlearn/layout/menu/menu.vue'
import MLFooter from '~/components/mlearn/layout/footer/footer.vue'

export default {
  components: {
    MLMenu,
    MLFooter
  },
  data () {
    return {
      deferredPrompt: null
    }
  },
  created () {
    if (process.client) {
      this.handleInstallCheckPWA()
      this.handleConfigNotifyFCM()
    }
  },
  computed: {
    ...mapState([
      'user',
      'isMobile'
    ])
  },
  methods: {
    handleConfigNotifyFCM () {
      Notification.requestPermission().then((result) => {
        console.info('Notify permission: ', result)
        if (result === 'granted') {
          this.$fire.messaging.getToken().then((currentToken) => {
            console.info('CurrentToken: ', currentToken)
            this.$fire.messaging.onMessage((payload) => {
              console.info('Message received: ', payload)
              // let notifyBody = payload['notification'];
              // this.commonNotifyVue(notifyBody['body'], 'info');
            })
          })
        }
      })
    },
    handleInstallCheckPWA () {
      console.info('Notify permission: ')

      if (window.matchMedia('(display-mode: standalone)').matches || window.navigator.standalone === true) {
        // User is currently navigating on the PWA so yes it's installed
        console.log('TRUE')
      } else {
        // User is navigating in browser
        window.addEventListener('beforeinstallprompt', (e) => {
          if (Cookies.get('add-to-home-screen') === undefined) {
            this.deferredPrompt = e
          }
          // User can get an installation prompt meaning the app is not installed
        })
        window.addEventListener('onappinstalled', () => {
          this.deferredPrompt = null
        })
      }
    },
    handleInstallBtnNo () {
      Cookies.set('add-to-home-screen', null, { expires: 7 })
      this.deferredPrompt = null
    },
    handleInstallBtnYes () {
      this.deferredPrompt.prompt()
    }
  }
}
</script>
<style lang="scss" scoped>
  .sticky-notification{
    position: fixed; top: 0; left: 0; width: 100%; height: 100px; z-index: 99999; padding: 25px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  }
  .ml-content{
    margin-top: 95px;
    @media (max-width:1366px) {
      margin-top: 90px;
    }
    @media (max-width:576px) {
      margin-top: 60px;
    }
  }
</style>
